<h3 class="settings-heading">{{ 'settings.model' | translate }}</h3>
<mat-form-field class="w-100" appearance="outline">
  <!-- Open Angular issue on "Incorrect use of <label for=FORM_ELEMENT>" https://github.com/angular/components/issues/27241 -->
  <mat-select [ngModel]="selectedModel" (ngModelChange)="onSelectModel($event)">
    <mat-option [value]="ModelType.GPT4o_MINI">OpenAI GPT-4o Mini</mat-option>
    <mat-option [value]="ModelType.GPT_4o">OpenAI GPT-4o</mat-option>
    <mat-option [value]="ModelType.GEMINI_FLASH"
      >Google Gemini 1.5 Flash</mat-option
    >
    <mat-option [value]="ModelType.GEMINI_PRO"
      >Google Gemini 1.5 Pro</mat-option
    >
    <mat-option [value]="ModelType.LLAMA_3">Meta Llama 3.1</mat-option>
    <mat-option
      *ngIf="this.translateService.currentLang === 'us'"
      [value]="ModelType.MISTRAL"
      >Mistral Large</mat-option
    >
    <mat-option
      *ngIf="this.translateService.currentLang !== 'ee'"
      [value]="ModelType.COMMAND_R"
      >Cohere Command R+</mat-option
    >
    <mat-option [value]="ModelType.CLAUDE">Anthropic Claude 3 Opus</mat-option>

    <!-- <mat-option
      [value]="ModelType.BART"
      *ngIf="environment.name !== 'production'"
      >Bart</mat-option
    > -->
  </mat-select>
</mat-form-field>

<!-- <p>
  <mat-checkbox
    (change)="selectAnswerType($event)"
    [checked]="isUseWebSearch(selectedAnswerType$ | async)">
    <span class="checkbox-label">
      {{ 'settings.webSearch' | translate }}
      <mat-icon
        matTooltip="{{ 'settings.webSearchTooltip' | translate }}"
        class="tooltip-icon"
        inline>
        info
      </mat-icon>
    </span>
  </mat-checkbox>
</p> -->

<!-- <p>
  <mat-checkbox (change)="updateIsHybridSearch($event)">
    <span class="checkbox-label">
      {{ 'settings.hybridSearch' | translate }}
      <mat-icon
        matTooltip="{{ 'settings.hybridSearchTooltip' | translate }}"
        class="tooltip-icon"
        inline>
        info
      </mat-icon>
    </span>
  </mat-checkbox>
</p> -->

<h3 class="settings-heading">{{ 'settings.searchMode' | translate }}</h3>
<mat-radio-group
  aria-labelledby="radio-group-label"
  class="radio-group"
  [ngModel]="searchMode"
  (ngModelChange)="updateSearchMode($event)">
  <mat-radio-button
    class="radio-button"
    color="primary"
    *ngFor="let mode of searchModes"
    [value]="mode">
    {{ 'settings.' + mode + 'Search' | translate }}
    <mat-icon
      matTooltip="{{ 'settings.' + mode + 'SearchTooltip' | translate }}"
      class="tooltip-icon"
      inline>
      info
    </mat-icon>
  </mat-radio-button>
</mat-radio-group>

<h3 class="settings-heading pt-4">Top_K:</h3>
<app-slider></app-slider>

<!-- <p *ngIf="environment.name !== 'production'">
  <mat-checkbox (change)="updateIsSummarization($event)">
    <span class="checkbox-label">
      {{ 'settings.summarization' | translate }}
      <mat-icon
        matTooltip="[Q & A ONLY] {{
          'settings.summarizationTooltip' | translate
        }}"
        class="tooltip-icon"
        inline>
        info
      </mat-icon>
    </span>
  </mat-checkbox>
</p> -->

<!-- <p *ngIf="(selectedConfiguration | async)?.searchSettings?.second_index">
  <mat-form-field class="select-field" appearance="outline">
    <mat-label>
      {{ 'settings.useSecondIndex' | translate }} [Q & A ONLY]
    </mat-label>
    <mat-select
      (ngModelChange)="selectIndex($event)"
      [ngModel]="selectedIndex">
      <mat-option [value]="null"> No focus branch selected </mat-option>
      <mat-option [value]="searchIndex.NORTAL_MARKETING_INDEX">
        {{ 'settings.useMarketingIndex' | translate }}
      </mat-option>
      <mat-option [value]="searchIndex.NORTAL_HR_INDEX">
        {{ 'settings.useHrIndex' | translate }}
      </mat-option>
      <mat-option [value]="searchIndex.PB_MARKETING_INDEX">
        {{ 'settings.usePBMarketingIndex' | translate }}
      </mat-option>
      <mat-option [value]="searchIndex.JOHNSTONE_SUPPLY">
        {{ 'settings.useJohnstoneSupply' | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</p> -->
