import { ModelType } from 'src/app/shared/model/query-body.model';

export interface Configuration {
  title: string;
  shortName: string;
  logoFileName: string;
  searchSettings: {
    index_name: SearchIndex;
    answerType: AnswerType;
    retriever_number: number;
    temperature: number;
    second_index?: string;
    hybrid_search?: boolean;
    document_chat_index?: SearchIndex;
  };
  examples: ExampleQuestion[];
}

export enum SearchIndex {
  NORTAL_AKS_INDEX = 'nortal_web_cosine,poc_sharepoint_cosine_llama',
  DOCUMENT_CHAT_INDEX = 'poc_documentchat',
  COMPASS_GROUP = 'compass_group_improved',
  AKTIVA_FINANCE_GROUP = 'perfi_ee',
  TELE2_INDEX = 'poc_tele2',
  NEOM = 'neom',
  TEIJIN_ARAMID = 'teijin_aramid_demo',
  // Old indexes:
  AI_WORKSHOP_INDEX = 'poc_ai_workshop',
  BERLINER_VOLKSBANK_INDEX = 'poc_berliner_volksbank',
  BESCHA_INDEX = 'poc_bescha',
  EAU_STATISTICS_INDEX = 'poc_statistics_centre',
  EE_INDEX = 'ee_index',
  ESDEC_CRAWLER_INDEX = 'poc_esdec',
  ESDEC_DOCS_INDEX = 'poc_esdec_docs',
  ISBJ_INDEX = 'poc_isbj',
  JATHEON = 'jatheon_email_index',
  KONECRANES_INDEX = 'poc_konecranes',
  KSA_INDEX = 'saudi_clean_index',
  KSA_MISA_INDEX = 'poc_misa_data_cosine',
  NLGA_INDEX = 'poc_nlga',
  NORTAL_HR_INDEX = 'hr_index',
  NORTAL_MARKETING_INDEX = 'project_bank_index',
  OTK_DOCS_INDEX = 'poc_otk_docs',
  PB_MARKETING_INDEX = 'poc_pb_marketing',
  PIF_INDEX = 'pif_index',
  POC_OTK_CRAWLER = 'poc_otk_crawler',
  PROPOSAL_GENERATOR_INDEX = 'proposal_generator_rfps',
  QA_INDEX_NAME_WEB = 'poc_nortal',
  SHAREPOINT_INDEX = 'poc_sharepoint_cosine_v1',
  SW_INDEX = 'poc_stadtsparkasse_wuppertal',
  WM_NIEDERSACHSEN_INDEX = 'poc_wm_niedersachsen',
  JOHNSTONE_SUPPLY = 'poc_johnstone_supply',
  AVEATO_INDEX = 'poc_aveato',
  BUNDESAGENTUR_FUER_ARBEIT_INDEX = 'poc_bundesagentur_fuer_arbeit',
  BLIGGIT_INDEX = 'poc_bliggit',
  ENVIR_INDEX = 'poc_envir',
  PWC_INDEX = 'poc_pwc',
  CALDWELL_COUNTY_INDEX = 'poc_caldwell',
  BOSCH_HEALTH_INDEX = 'poc_bosch_health',
  OIKEUSREKISTERIKESKUS = 'poc_oikeusrekisterikeskus'
}

export enum AnswerType {
  LFGA = 'lfqa',
  LFGA_GOOGLE = 'lfqa_google',
  LFGA_AGENT = 'lfqa_agent',
  EXTRACTIVE = 'extractive',
  GENERATIVE = 'generative'
}

export interface ExampleQuestion {
  flagClass: string; // 2-digit value from here https://flagicons.lipis.dev/
  language: string;
  questions: string[];
}

export const CONFIGURATION_NAME = 'configurationName';
export const CHAT_HISTORY = 'chatHistory';
export const QA_HISTORY = 'Q&AHistory';

export interface NameValue {
  name: string;
  value: string | number | ModelType;
}

export enum AuthOperation {
  LOGIN = 'login',
  LOGOUT = 'logout'
}
