import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ChatMetadata, StatefulChatHistory, StatefulChatResponse } from '../../../shared/model/stateful-chat.model';
import { ChatMessage, UserMessage } from '../../chatbot/model/chat-message.model';

@Injectable({
  providedIn: 'root'
})
export class StatefulChatService {

  private httpClient = inject(HttpClient);

  private url = environment.statefulChatBackendUrl;

  continueChat(chatUuid: string, userMsg: string) {
    const userMessage: UserMessage = { user: userMsg, userTimestamp: null };

    const body = JSON.stringify({
      conversation_uuid: chatUuid,
      message: userMsg,
    })

    return this.httpClient.post<StatefulChatResponse>(this.url + '/continue_chat', body, {
      headers: {
        'Content-Type': 'application/json'
      },
    }).pipe(
      map((response: StatefulChatResponse) => {
        return this.mapResponseToChatMessage(response, userMessage);
      })
    );
  }

  respondToLastUserMessage(chatUuid: string) {
    const body = JSON.stringify({
      conversation_uuid: chatUuid
    })

    return this.httpClient.post<StatefulChatResponse>(this.url + '/respond_to_last_message', body, {
      headers: {
        'Content-Type': 'application/json'
      },
    }).pipe(
      map((response: StatefulChatResponse) => {
        return this.mapBotResponseToChatMessage(response);
      })
    );
  }

  getChatHistory(chatId: string) {
    return this.httpClient.get<StatefulChatHistory[]>(this.url + '/retrieve_all_messages?conversation_uuid=' + chatId, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).pipe(
      map((response: StatefulChatHistory[]) => {
        return this.mapHistoryResponseToChatMessages(response);
      })
    );
  }

  getChatMetadata(chatId: string) {
    return this.httpClient.get<ChatMetadata>(this.url + '/retrieve_confluence_metadata?conversation_uuid=' + chatId, {
      headers: {
        'Content-Type': 'application/json'
      },
    }).pipe(
      map((response: ChatMetadata) => {
        return response;
      })
    );
  }

  mapUserMessageToChatMessage (message: string) : ChatMessage {
    return {
      userMessage: { user: message, userTimestamp: null },
      botMessage: { bot: { answerText: '', sources: [], runtimeInMillis: 0, index: '', error_code: null, prompt: null, userQuery:'' }, botTimestamp: null }
    }
  }

  private mapBotResponseToChatMessage(response: StatefulChatResponse): ChatMessage {
    return {
      userMessage: {user:'', userTimestamp: null},
      botMessage: { bot: { answerText: response.chat_response.response, sources: [], runtimeInMillis: 0, index: '', error_code: null, prompt: null, userQuery:'' }, botTimestamp: null }
    }
  }

  private mapResponseToChatMessage(response: StatefulChatResponse, userMessage: UserMessage): ChatMessage {
    return {
      userMessage,
      botMessage: { bot: { answerText: response.chat_response.response, sources: [], runtimeInMillis: 0, index: '', error_code: null, prompt: null, userQuery:'' }, botTimestamp: null }
    }
  }

  private mapHistoryResponseToChatMessages(response: StatefulChatHistory[]): ChatMessage[] {
    return response.map(item => {
      const defaultUserMessage = { user: 'Default User Message', userTimestamp: null };
      const defaultBotMessage = { bot: { answerText: '', sources: [], runtimeInMillis: 0, index: '', error_code: null, prompt: null, userQuery: '' }, botTimestamp: null };

      if (item.role === 'user') {
        return {
          userMessage: { user: item.blocks[0].text, userTimestamp: null },
          botMessage: { bot: null, botTimestamp: null }
        };
      } else if (item.role === 'assistant') {
        return {
          userMessage: { user: '', userTimestamp: null },
          botMessage: { bot: { ...defaultBotMessage.bot, answerText: item.blocks[0].text }, botTimestamp: null }
        };
      } else {
        return {
          userMessage: defaultUserMessage,
          botMessage: { ...defaultBotMessage, bot: { ...defaultBotMessage.bot, answerText: item.blocks[0].text } }
        };
      }
    });
  }
}
