import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ThemeColorService {
  private metaThemeColor: HTMLMetaElement | null;

  constructor() {
    this.metaThemeColor = document.querySelector('meta[name=theme-color]');
    this.setThemeColor(environment.themeColor);
  }

  setThemeColor(color: string): void {
    if (this.metaThemeColor) {
      this.metaThemeColor.setAttribute('content', color);
    }
  }
}
