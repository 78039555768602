import { BreakpointObserver } from '@angular/cdk/layout';
import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

import {
  BehaviorSubject,
  delay,
  EMPTY,
  expand,
  filter,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';

import {
  ChatMessage,
  ShortenedMessage
} from '../../features/chatbot/model/chat-message.model';
import { ChatbotService } from '../../features/chatbot/data-access/chatbot.service';
import { EmailService } from '../../features/feedback/data-access/email.service';
import { History } from '../../features/query-history/model/history.model';
import { QueryService } from '../../features/query/data-access/query.service';

import {
  ChatQuerySettings,
  ModelType,
  QuestionAnswerQuerySettings,
  SearchMode
} from '../../shared/model/query-body.model';

import {
  CHAT_HISTORY,
  Configuration,
  CONFIGURATION_NAME,
  QA_HISTORY,
  SearchIndex
} from '../model/configurations.model';
import { NORTAL_GPT } from '../model/configurations/nortalGPT';
import { COMPASS_GROUP } from '../model/configurations/compass_group';
import { AKTIVA_FINANCE_GROUP } from '../model/configurations/aktiva_finance_group';
import { NEOM } from '../model/configurations/neom';
import { TEIJIN_ARAMID } from '../model/configurations/teijin_aramid';
import { TELE2 } from '../model/configurations/tele2';
import { EmailModel } from '../model/email.model';
//import { CALDWELL_COUNTY_INDEX } from '../model/configurations/caldwell_county';
//import { BOSCH_HEALTH_INDEX } from '../model/configurations/bosch_health';

import { Answer } from 'src/app/shared/model/answer.model';
import { QueryFilter } from 'src/app/shared/model/query-filter.model';

import {
  FileUploadService,
  GetProposalResponse,
  GetUploadResponse
} from './file-upload.service';

import { LoaderService } from './loader.service';
import { NavigationStart, Router } from '@angular/router';
import { MenuRootRoute, MenuSubRoute } from 'src/app/app.routes';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private queryService = inject(QueryService);
  private emailService = inject(EmailService);
  private chatbotService = inject(ChatbotService);
  private fileUploadService = inject(FileUploadService);
  private snackBar = inject(MatSnackBar);
  private breakpointObserver = inject(BreakpointObserver);
  private translateService = inject(TranslateService);
  private loader = inject(LoaderService);
  private router = inject(Router);

  /* Properties: StateService */
  private abortFileUploadSignal$ = new Subject<void>();
  private queryFilters$ = new BehaviorSubject<QueryFilter>({
    sector: [],
    industry_type: [],
    service_offering: []
  });
  readonly chatHistory$ = new BehaviorSubject<ChatMessage[]>([]);

  /* Properties: whole app */
  currentLang$ = new BehaviorSubject(this.translateService.currentLang);
  readonly selectedConfiguration$: BehaviorSubject<Configuration>;
  readonly configurations$ = new BehaviorSubject<Configuration[]>([
    NORTAL_GPT,
    COMPASS_GROUP,
    AKTIVA_FINANCE_GROUP,
    TELE2,
    NEOM,
    TEIJIN_ARAMID
  ]);
  readonly isLoading$ = new BehaviorSubject<boolean>(false);
  readonly isSideNavOpen$ = new BehaviorSubject<boolean>(true);
  readonly isTabletLandscapeUp$ = this.breakpointObserver
    .observe('(min-width: 900px)')
    .pipe(
      map(state => state.matches),
      tap(isTabletLandscapeUp => {
        this.isSideNavOpen$.next(isTabletLandscapeUp);
      })
    );
  readonly activeMenuRootRoute$ = new BehaviorSubject<
    MenuRootRoute | undefined
  >(MenuRootRoute.INTELLIGENT_SEARCH);
  readonly activeMenuSubRoute$ = new BehaviorSubject<MenuSubRoute | undefined>(
    MenuSubRoute.QA
  );
  readonly isDarkMode$ = new BehaviorSubject<boolean>(false);

  /* Properties: settings */
  readonly selectedModel$ = new BehaviorSubject<ModelType>(ModelType.GPT_4o);
  readonly searchMode$ = new BehaviorSubject<SearchMode>(SearchMode.SEMANTIC);
  readonly isHybridSearch$ = new BehaviorSubject<boolean>(false);
  readonly topKValue$ = new BehaviorSubject<number>(6);

  readonly isIntelligentSearchQA$ = new BehaviorSubject<boolean>(true);
  readonly history$ = new BehaviorSubject<History[]>([]);
  readonly isUseSecondIndex$ = new BehaviorSubject<boolean>(false);

  readonly isShowExpertMode$ = new BehaviorSubject<boolean>(false);
  readonly isExpertMode$ = new BehaviorSubject<boolean>(false);
  readonly expertModeTemperature$ = new BehaviorSubject<number>(0.1);

  /* readonly selectedAnswerType$ = new BehaviorSubject<AnswerType>(
    AnswerType.LFGA
  ); */
  /* readonly isSummarization$ = new BehaviorSubject<boolean>(false); */
  /* readonly selectedIndex$ = new BehaviorSubject<string | undefined>(undefined); */
  /* readonly models$ = new BehaviorSubject<ModelType[]>(
    // TODO ENV_TOGGLE remove this if to make open source LLM feature available in production
    environment.name !== 'production'
      ? [ModelType.GPT3_Turbo, ModelType.GPT4, ModelType.BART]
      : [ModelType.GPT3_Turbo, ModelType.GPT4]
  ); */

  /* Properties: Intelligent Search - Q&A */
  readonly isArabic$ = new BehaviorSubject<boolean>(false);

  /* Properties: Intelligent Search - Q&A, Document chatting */
  readonly example$ = new Subject<string>();
  readonly answer$ = this.queryService.answer$;

  /* Properties: Intelligent Search - Chatting*/
  readonly exampleQuestion$ = new BehaviorSubject<string>('');

  /* Properties: Intelligent Search - Chatting, Document chatting */
  readonly chat$ = this.chatbotService.chat$;
  protected taskIds$ = new BehaviorSubject<string[]>([]);

  /* Properties: Document chatting */
  readonly isDocChatCtaDisabled$ = new BehaviorSubject<boolean>(false);

  /* Properties: Document chatting, proposal generator */
  protected uploadedFiles$ = new BehaviorSubject<File[]>([]);

  /* Properties: Document generator */
  readonly uploadedGeneratorFiles$ = new BehaviorSubject<File[]>([]);
  protected generatedProposalUrl$ = new BehaviorSubject<string>('');

  constructor() {
    this.selectedConfiguration$ = new BehaviorSubject<Configuration>(
      this.loadConfiguration()
    );
    this.isDarkMode$.next(this.loadIsDarkMode());
    this.subscribeToConfiguration();
    this.subscribeToHistoryUpdates();
    this.subscribeToChatUpdates();
    this.subscribeToURLChanges();
    this.subscribeToLangChanges();
    this.subscribeToDarkModeChanges();
  }

  private subscribeToConfiguration(): void {
    this.selectedConfiguration$
      .pipe(takeUntilDestroyed())
      .subscribe(configuration => {
        localStorage.setItem(CONFIGURATION_NAME, configuration.shortName);
        this.abortFileUploadSignal$.next();
        this.uploadedFiles$.next([]);
        this.loader.isLoading$.next(false);
      });
  }

  private subscribeToHistoryUpdates() {
    this.queryService.historyUpdated$
      .pipe(takeUntilDestroyed())
      .subscribe((res: Answer) => {
        if (res.answerText) {
          const answer: Answer = res;
          const history: History = {
            query: answer.userQuery ?? '',
            answer: answer
          };
          this.history$.next([history, ...this.history$.value]);
          const currentConfigName =
            this.selectedConfiguration$.value.shortName.replace(/\s+/g, '_');
          localStorage.setItem(
            `${QA_HISTORY}_${currentConfigName}`,
            JSON.stringify(this.history$.value)
          );
        }
      });
  }

  private subscribeToChatUpdates() {
    this.chatbotService.chatUpdated$
      .pipe(takeUntilDestroyed())
      .subscribe((res: ChatMessage[]) => {
        const currentUrlPath = window.location.href;
        if (
          !currentUrlPath.includes(MenuRootRoute.DOCUMENT_CHATTING) &&
          res.length
        ) {
          this.chatHistory$.next(res);

          const currentConfigName =
            this.selectedConfiguration$.value.shortName.replace(/\s+/g, '_');
          localStorage.setItem(
            `${CHAT_HISTORY}_${currentConfigName}`,
            JSON.stringify(this.chatHistory$.value)
          );
        }
      });
  }

  private subscribeToURLChanges() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        takeUntilDestroyed()
      )
      .subscribe(navigation => {
        const { url } = navigation as NavigationStart;
        const [rootRoute, subRoute] = this.extractRoutesFromURL(url);

        const nextRootRoute = Object.values(MenuRootRoute).find(
          enumValue => enumValue === rootRoute
        );
        const nextSubRoute = Object.values(MenuSubRoute).find(
          enumValue => enumValue === subRoute
        );

        this.updateMenuRoutes(nextRootRoute, nextSubRoute);
        this.updatePageSpecificFlags(url);

        this.abortFileUploadSignal$.next();
        this.uploadedFiles$.next([]);
        this.loader.isLoading$.next(false);
      });
  }

  private subscribeToDarkModeChanges() {
    this.isDarkMode$.pipe(takeUntilDestroyed()).subscribe(isDarkMode => {
      localStorage.setItem('isDarkMode', String(isDarkMode));
    });
  }

  private extractRoutesFromURL(
    url: string
  ): [string | undefined, string | undefined] {
    const urlSegments = url.replace(/^\/|\/$/g, '').split('/');
    const rootRoute = urlSegments[0];
    const subRoute = urlSegments.length > 1 ? urlSegments[1] : undefined;
    return [rootRoute, subRoute];
  }

  private updateMenuRoutes(
    nextRootRoute: MenuRootRoute | undefined,
    nextSubRoute: MenuSubRoute | undefined
  ) {
    if (
      (!nextRootRoute && !nextSubRoute) ||
      (nextRootRoute === MenuRootRoute.INTELLIGENT_SEARCH && !nextSubRoute)
    ) {
      this.activeMenuRootRoute$.next(MenuRootRoute.INTELLIGENT_SEARCH);
      this.activeMenuSubRoute$.next(MenuSubRoute.QA);
    } else if (
      nextRootRoute === MenuRootRoute.DOCUMENT_GENERATOR &&
      !nextSubRoute
    ) {
      this.activeMenuRootRoute$.next(MenuRootRoute.DOCUMENT_GENERATOR);
      this.activeMenuSubRoute$.next(MenuSubRoute.GENERATE_PROPOSAL);
    } else {
      this.activeMenuRootRoute$.next(nextRootRoute);
      this.activeMenuSubRoute$.next(nextSubRoute);
    }
  }

  private updatePageSpecificFlags(url: string) {
    const isIntelligentSearchChatUrl =
      url === `/${MenuRootRoute.INTELLIGENT_SEARCH}/${MenuSubRoute.CHAT}`;
    const isIntelligentSearchQAUrl =
      url === '/' ||
      url === `/${MenuRootRoute.INTELLIGENT_SEARCH}/${MenuSubRoute.QA}` ||
      (!isIntelligentSearchChatUrl &&
        url === `/${MenuRootRoute.INTELLIGENT_SEARCH}`);
    const isDocumentChattingUrl = url === `/${MenuRootRoute.DOCUMENT_SEARCH}`;

    this.isIntelligentSearchQA$.next(isIntelligentSearchQAUrl);
    this.isShowExpertMode$.next(
      isIntelligentSearchQAUrl ||
        isIntelligentSearchChatUrl ||
        isDocumentChattingUrl
    );
  }

  private subscribeToLangChanges(): void {
    this.translateService.onLangChange.subscribe(lang => {
      this.updateExampleQuestion();
      this.currentLang$.next(lang.lang);
    });
  }

  private updateExampleQuestion() {
    const showExampleQuestion =
      this.chatbotService.chatHistorySubject.getValue().length === 1;
    if (showExampleQuestion) {
      this.exampleQuestion$.next(
        this.translateService.instant('example.exampleQuestion')
      );
    }
  }

  updateSelectedConfiguration(configuration: Configuration): void {
    this.selectedConfiguration$.next(configuration);
    this.loadChatHistory();
    this.loadQAHistory();

    this.clearAnswer();

    this.uploadedFiles$.next([]);
    this.isLoading$.next(false);

    /* this.selectedAnswerType$.next(
      this.selectedConfiguration$.value.searchSettings.answerType
    ); */
  }

  useExample(example: string, isArabic: boolean): void {
    this.example$.next(example);
    this.exampleQuestion$.next(example);
    this.isArabic$.next(isArabic);
  }

  uploadFile(files: File[]): void {
    this.loader.isLoading$.next(true);
    this.fileUploadService
      .uploadFile(files)
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: response => {
          this.loader.isLoading$.next(false);
          this.uploadedFiles$.next([...this.uploadedFiles$.value, response]);
        },
        error: (error: unknown) => this.errorHandler(error)
      });
  }

  uploadDocChatFiles(files: File[]): void {
    this.abortFileUploadSignal$.next();
    this.loader.isLoading$.next(true);
    this.uploadedFiles$.next([]);
    const delayTime = Math.min(files.length * 2000, 12000);

    this.fileUploadService
      .postUploadFiles(files)
      .pipe(
        takeUntil(this.abortFileUploadSignal$),
        switchMap(response => {
          if (response.taskId) {
            const taskId = response.taskId;
            return this.fileUploadService.getUploadFiles(taskId).pipe(
              expand((getResponse: GetUploadResponse) => {
                return getResponse.status_code === 202
                  ? of(null).pipe(
                      delay(delayTime),
                      switchMap(() =>
                        this.fileUploadService.getUploadFiles(taskId)
                      ),
                      takeUntil(this.abortFileUploadSignal$)
                    )
                  : EMPTY;
              }),
              takeUntil(this.abortFileUploadSignal$)
            );
          } else {
            return of(null);
          }
        })
      )
      .subscribe({
        next: response => {
          if (response?.status_code === 200 || response?.status_code === 404) {
            this.uploadedFiles$.next(files);
            if (response.task_id !== undefined) {
              this.taskIds$.next([response.task_id]);
            }
            this.loader.isLoading$.next(false);
          } /* else {
            console.log(
              'No response or response with status_code other that 200, 202 and 404'
            );
          } */
        },
        error: error => this.errorHandler(error)
      });
  }

  uploadProposalGeneratorFile(file: File): void {
    this.abortFileUploadSignal$.next();
    this.loader.isLoading$.next(true);
    this.uploadedFiles$.next([]);
    this.generatedProposalUrl$.next('');

    this.fileUploadService
      .postRfpFile(file)
      .pipe(
        takeUntil(this.abortFileUploadSignal$),
        switchMap(response => {
          if (response.taskId) {
            this.uploadedFiles$.next([file]);
            const taskId = response.taskId;
            return this.fileUploadService.getProposal(taskId).pipe(
              expand((getResponse: GetProposalResponse) => {
                return getResponse.status_code === 202
                  ? of(null).pipe(
                      delay(5000),
                      switchMap(() =>
                        this.fileUploadService.getProposal(taskId)
                      ),
                      takeUntil(this.abortFileUploadSignal$)
                    )
                  : EMPTY;
              }),
              takeUntil(this.abortFileUploadSignal$)
            );
          } else {
            return of(null);
          }
        })
      )
      .subscribe({
        next: response => {
          if (response?.status_code === 200 || response?.status_code === 404) {
            this.generatedProposalUrl$.next(response.url);
            this.loader.isLoading$.next(false);
          } /* else {
            console.log(
              'No response or response with status_code other that 200, 202 and 404'
            );
          } */
        },
        error: error => this.errorHandler(error)
      });
  }

  async fetchData(query: string): Promise<void> {
    //const clientName = this.selectedConfiguration$.getValue().shortName;
    const querySettings: QuestionAnswerQuerySettings =
      this.getQuestionAnswerQuerySettings();

    /* const filters =
      clientName === ClientName.NORTAL_TARK
        ? this.queryFilters$.getValue()
        : undefined; */

    this.queryService.handleStreamedData(
      query,
      querySettings,
      this.isArabic$.getValue()
      //filters
    );
  }

  async chat(
    message: string,
    filterByFiles?: string[],
    filterByTaskId?: string[]
  ): Promise<void> {
    const chatHistoryShortened: ShortenedMessage[] =
      this.chatHistory$.value.map(chatMessage => ({
        user: chatMessage?.userMessage?.user ?? undefined,
        bot: chatMessage?.botMessage?.bot?.answerText ?? undefined
      }));
    this.example$.next('');

    const isDocumentChatting = filterByFiles && filterByFiles?.length > 0;
    const chatQuerySettings: ChatQuerySettings =
      this.getChatQuerySettings(isDocumentChatting);

    this.chatbotService
      // reverse the chat history before sending it to the backend:
      .handleChat(
        message,
        [...chatHistoryShortened], //.reverse(),
        chatQuerySettings,
        filterByFiles,
        filterByTaskId
      );
  }

  clearAnswer(): void {
    this.queryService.stopStreaming();

    if (this.queryService.requestInProgress$.value) {
      this.loader.isLoading$.next(true);
    }

    setTimeout(() => {
      this.queryService.answerSubject$.next(undefined);
      this.queryService.setRequestInitialState();
      this.example$.next('');
    }, 500);

    this.queryService.requestInProgress$.next(false);
    this.loader.isLoading$.next(false);
  }

  selectAnswer(history: History) {
    this.queryService.answerSubject$.next(history.answer);
    this.example$.next(history.query);
  }

  toggleSideNav(isOpen?: boolean): void {
    if (isOpen !== undefined) {
      this.isSideNavOpen$.next(isOpen);
      return;
    }
    this.isSideNavOpen$.next(!this.isSideNavOpen$.value);
  }

  sendFeedback(emailData: EmailModel) {
    this.emailService
      .sendEmail(emailData)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.snackBar.open(
            `Feedback submitted successfully`,
            this.translateService.instant('buttons.close'),
            {
              panelClass: ['mat-snackbar-success'],
              duration: 3000
            }
          );
        },
        error: error => this.errorHandler(error)
      });
  }

  getChatWelcomeMessage(): ChatMessage {
    return {
      userMessage: {
        user: '',
        userTimestamp: null
      },
      botMessage: {
        bot: {
          answerText: this.translateService.instant('example.welcomeMessage'),
          error_code: null,
          index: '',
          prompt: null,
          runtimeInMillis: undefined,
          sources: []
        },
        botTimestamp: new Date().toLocaleString()
      }
    };
  }

  loadQAHistory() {
    const configName = this.selectedConfiguration$.value.shortName.replace(
      /\s+/g,
      '_'
    );
    const savedQAHistory = JSON.parse(
      localStorage.getItem(`${QA_HISTORY}_${configName}`) ?? '[]'
    ) as History[];

    this.history$.next(savedQAHistory);
  }

  clearQAHistory() {
    const configName = this.selectedConfiguration$.value.shortName.replace(
      /\s+/g,
      '_'
    );
    localStorage.removeItem(`${QA_HISTORY}_${configName}`);
    this.history$.next([]);
    this.snackBar.open(
      this.translateService.instant('history.historyCleared'),
      this.translateService.instant('buttons.close'),
      {
        panelClass: ['mat-snackbar-success'],
        duration: 3000
      }
    );
  }

  loadChatHistory() {
    const configName = this.selectedConfiguration$.value.shortName.replace(
      /\s+/g,
      '_'
    );

    const savedChatHistory = JSON.parse(
      localStorage.getItem(`${CHAT_HISTORY}_${configName}`) ?? '[]'
    ) as ChatMessage[];

    if (savedChatHistory.length === 0) {
      savedChatHistory.push(this.getChatWelcomeMessage());
    }
    this.chatbotService.updateChatHistory(savedChatHistory);
    this.chatHistory$.next(savedChatHistory);
  }

  setEmptyChatHistory() {
    this.exampleQuestion$.next(
      this.translateService.instant('example.exampleQuestion')
    );
    this.chatbotService.updateChatHistory([this.getChatWelcomeMessage()]);
    this.chatHistory$.next([this.getChatWelcomeMessage()]);
  }

  clearChatHistory() {
    this.chatbotService
      .deleteHistory()
      .pipe(take(1))
      .subscribe({
        next: response => {
          this.snackBar.open(
            response,
            this.translateService.instant('buttons.close'),
            {
              panelClass: ['mat-snackbar-success'],
              duration: 3000
            }
          );
          const configName =
            this.selectedConfiguration$.value.shortName.replace(/\s+/g, '_');
          localStorage.removeItem(`${CHAT_HISTORY}_${configName}`);
          this.chatHistory$.next([]);
          this.chatbotService.updateChatHistory([]);
          this.loadChatHistory();
        },
        error: (error: unknown) => this.errorHandler(error)
      });
  }

  getUploadedFilesStream(): Observable<File[]> {
    return this.uploadedFiles$;
  }

  getTaskIdsStream(): Observable<string[]> {
    return this.taskIds$;
  }

  getGeneratedProposalUrl(): Observable<string> {
    return this.generatedProposalUrl$;
  }

  getQueryFilters(): QueryFilter {
    return this.queryFilters$.getValue();
  }

  setQueryFilters(filters: QueryFilter): void {
    this.queryFilters$.next(filters);
  }

  updateSelectedModel(model: ModelType) {
    this.selectedModel$.next(model);
    const config = this.selectedConfiguration$.value;
    config.searchSettings.retriever_number = model === ModelType.GPT_4o ? 7 : 6;
    this.selectedConfiguration$.next(config);
  }

  updateSearchMode(mode: SearchMode) {
    this.searchMode$.next(mode);
  }

  updateIsHybridSearch(hybridSearch: boolean): void {
    this.isHybridSearch$.next(hybridSearch);
  }

  updateTopKValue(value: number): void {
    this.topKValue$.next(value);
  }

  updateIsDocChatCtaDisabled(isDisabled: boolean): void {
    this.isDocChatCtaDisabled$.next(isDisabled);
  }

  updateIsExpertMode(isExpertMode: boolean): void {
    this.isExpertMode$.next(isExpertMode);
  }

  updateExpertModeTemperature(temperature: number): void {
    this.expertModeTemperature$.next(temperature);
  }

  private errorHandler(error: any): void {
    this.isLoading$.next(false);
    this.loader.isLoading$.next(false);
    this.snackBar.open(
      `Request failed with status code ${error.status}`,
      this.translateService.instant('buttons.close'),
      {
        panelClass: ['mat-snackbar-warn'],
        duration: 3000
      }
    );
  }

  private loadConfiguration(): Configuration {
    const configurationName = localStorage.getItem(CONFIGURATION_NAME);
    return (
      this.configurations$.value.find(
        configuration => configuration.shortName === configurationName
      ) || NORTAL_GPT
    );
  }

  private loadIsDarkMode(): boolean {
    const item = localStorage.getItem('isDarkMode');
    if (item) {
      return JSON.parse(item) === true;
    }
    return false;
  }

  private getQuestionAnswerQuerySettings(): QuestionAnswerQuerySettings {
    const body: QuestionAnswerQuerySettings = {
      index_name: this.selectedConfiguration$.value.searchSettings.index_name,
      similarity_top_k: this.topKValue$.value ?? 5,
      model: this.selectedModel$.value,
      search_mode: this.searchMode$.value,
      streaming: true,
      evaluate: false,
      temperature: this.expertModeTemperature$.value,
      expert_mode: this.isExpertMode$.value
    };

    return body;
  }

  private getChatQuerySettings(
    isDocumentChatting?: boolean
  ): ChatQuerySettings {
    const documentChatIndex: SearchIndex | undefined =
      this.selectedConfiguration$.value.searchSettings.document_chat_index;
    const index_name =
      isDocumentChatting && documentChatIndex
        ? documentChatIndex
        : this.selectedConfiguration$.value.searchSettings.index_name;

    return {
      index_name,
      similarity_top_k: this.topKValue$.value,
      model: this.selectedModel$.value,
      search_mode: this.searchMode$.value,
      streaming: true,
      evaluate: false,
      fast: true,
      temperature: this.expertModeTemperature$.value,
      expert_mode: this.isExpertMode$.value
    } as ChatQuerySettings;
  }

  /* private backEndHealthFail(): void {
    this.isLoading$.next(false);
    this.snackBar.open(
      this.translateService.instant('app.healthCheckFail'),
      this.translateService.instant('buttons.close'),
      {
        panelClass: ['mat-snackbar-warn']
      }
    );
  } */

  /* updateSelectedIndex(indexName: string) {
    this.selectedIndex$.next(indexName);
  } */

  /* updateIsSummarization(summarization: boolean): void {
    this.isSummarization$.next(summarization);
  } */

  /* updateSelectedAnswerType(answerType: AnswerType): void {
    this.selectedAnswerType$.next(answerType);
  } */

  /* updateIsUseSecondIndex(summarization: boolean): void {
    this.isUseSecondIndex$.next(summarization);
  } */

  /* getUploadedFiles(): File[] {
    return this.uploadedFiles$.getValue();
  } */

  /* setUploadedFiles(files: File[]): void {
    this.uploadedFiles$.next(files);
  } */

  /* makeQuery(query: string): void {
    this.loader.isLoading$.next(true);

    // const start = performance.now();
    const { querySettings, filters } = this.getQuerySettingsAndFilters();

    this.queryService.makeQuery(query, querySettings, filters).subscribe({
      next: response => {
        this.answer$.subscribe(res => {
          const answer: Answer = res;
          this.history$.next([
            {
              query,
              answer
            },
            ...this.history$.value
          ]);
          this.loader.isLoading$.next(false);
        });
      },
      error: error => this.errorHandler(error)
    });
  } */

  /* makeChatQuery(message: string): void {
    this.loader.isLoading$.next(true);

    const chatHistoryShortened: ShortenedMessage[] =
      this.chatHistory$.value.map(chatMessage => ({
        user: chatMessage.userMessage.user,
        bot: chatMessage.botMessage.bot?.answer
      }));

    const chatQuerySettings: Partial<QuerySettings> =
      this.getChatQuerySettings();
    const start = performance.now();
    const userTimestamp = new Date().toLocaleString();

    this.chatbotService
      // reverse the chat history before sending it to the backend:
      .sendMessage(
        message,
        [...chatHistoryShortened].reverse(),
        chatQuerySettings
      )
      .subscribe({
        next: response => {
          const end = performance.now();
          const runtimeInMillis = end - start;

          const userChatMessage: ChatMessage = {
            userMessage: {
              user: message,
              userTimestamp
            },
            botMessage: {
              bot: { ...response, runtimeInMillis },
              botTimestamp: new Date().toLocaleString()
            }
          };

          const newChatHistory = [userChatMessage, ...this.chatHistory$.value];

          // Clear the content in the answers of all previous responses ('cause of the limited space in localStorage)
          newChatHistory.slice(1).forEach(chatMessage => {
            if (chatMessage.botMessage.bot?.answers) {
              chatMessage.botMessage.bot.answers.forEach(
                document =>
                  (document.content =
                    'Content cleared to save localStorage space')
              );
            }
          });

          const configName =
            this.selectedConfiguration$.value.shortName.replace(/\s/g, '_');
          localStorage.setItem(
            `${CHAT_HISTORY}_${configName}`,
            JSON.stringify(newChatHistory)
          );
          this.chatHistory$.next(newChatHistory);
          this.loader.isLoading$.next(false);
        },
        error: error => this.errorHandler(error)
      });
  } */
}
