import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnChanges,
  SimpleChanges,
  inject
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { ExpertModeService } from '../../data-access/expert-mode.service';
import { StateService } from 'src/app/core/data-access/state.service';

@Component({
  selector: 'app-prompt-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDialogModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule
  ],
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromptDialogComponent implements OnChanges {
  private stateService: StateService = inject(StateService);
  private expertModeService: ExpertModeService = inject(ExpertModeService);
  public dialogRef = inject(MatDialogRef<PromptDialogComponent>);

  prompt = new FormControl(this.data.text);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { text: string }) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.prompt.patchValue(changes['data'].currentValue);
      this.prompt.enable();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  updatePrompt() {
    if (this.prompt.value) {
      const isQAPrompt = this.stateService.isIntelligentSearchQA$.getValue();
      this.expertModeService.setPrompt(this.prompt.value, false, isQAPrompt);
    }
  }
}
