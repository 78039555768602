import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, take, map, filter } from 'rxjs';
import { AccessTokenService } from 'src/app/core/data-access/access-token.service';

export const emailsWithAccess = [
  'alex.vahter@nortal.com',
  'andres.viikmaa@nortal.com',
  'annemari.pius@nortal.com',
  'bai-hao.ji@nortal.com',
  'carola.keskyla@nortal.com',
  'dinko.eichin-roitman@nortal.com',
  'egert.teesaar@nortal.com',
  'elena.sygis@nortal.com',
  'evgenij.nazarenus@nortal.com',
  'fidel.tewolde@nortal.com',
  'hannes.roos@nortal.com',
  'heidi.koppel@nortal.com',
  'herman.ratas@nortal.com',
  'indrek.lobus@nortal.com',
  'karlmartin.kadaja@nortal.com',
  'ken.tilk@nortal.com',
  'kunal.arora@nortal.com',
  'marcin.wardowski@nortal.com',
  'mladen.jovanovic@nortal.com',
  'rudesh.sekaran@nortal.com',
  'tonis.kangsepp@nortal.com',
  'ulan.yisaev@nortal.com',
  'yashika.sharma@nortal.com'
];

@Injectable({
  providedIn: 'root'
})
export class PosthogAccessGuard implements CanActivate {
  constructor(
    private accessTokenService: AccessTokenService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> {
    return this.accessTokenService.activeAccountEmail$.pipe(
      filter(email => email !== ''),
      take(1),
      map(activeAccountEmail => {
        const canAccess = emailsWithAccess.some(
          email => email.toLowerCase() === activeAccountEmail.toLowerCase()
        );

        this.accessTokenService.canAccessPostHog$.next(canAccess);

        if (!canAccess) {
          this.router.navigate(['/']);
          return false;
        }

        return true;
      })
    );
  }
}
