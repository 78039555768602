import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { StateService } from '../../../core/data-access/state.service';
import { ModelType, SearchMode } from 'src/app/shared/model/query-body.model';
import { SliderComponent } from '../../../shared/ui/slider/slider.component';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [
    AsyncPipe,
    CommonModule,
    MatRadioModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    SliderComponent
  ],
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsComponent {
  private stateService = inject(StateService);
  translateService = inject(TranslateService);

  //environment = environment;
  SearchMode: SearchMode;
  ModelType = ModelType;

  searchMode: string = SearchMode.SEMANTIC;
  searchModes: string[] = [
    SearchMode.HYBRID,
    SearchMode.SEMANTIC,
    SearchMode.TEXT
  ];

  selectedConfiguration = this.stateService.selectedConfiguration$;
  selectedModel$ = this.stateService.selectedModel$;
  selectedModel = this.selectedModel$.value;
  //selectedAnswerType$ = this.stateService.selectedAnswerType$;
  //selectedAnswerType = this.selectedAnswerType$.value;
  //selectedIndex = '';
  //selectedIndex$ = this.stateService.selectedIndex$;

  onSelectModel(event$: ModelType) {
    this.selectedModel = event$;
    this.stateService.updateSelectedModel(event$);
  }

  updateSearchMode($event: SearchMode) {
    this.stateService.updateSearchMode($event);
  }

  /* selectIndex(event$: SearchIndex) {
    this.selectedIndex = event$ ? event$ : undefined;
    this.stateService.updateSelectedIndex(event$);
  } */

  /* isUseWebSearch(selectedAnswerType: AnswerType | null) {
    return selectedAnswerType === AnswerType.LFGA_GOOGLE;
  } */

  /* selectAnswerType($event: MatCheckboxChange): void {
    this.selectedAnswerType = $event.checked
      ? AnswerType.LFGA_GOOGLE
      : AnswerType.LFGA;
    this.stateService.updateSelectedAnswerType(this.selectedAnswerType);
  } */

  /* updateIsSummarization($event: MatCheckboxChange): void {
    this.stateService.updateIsSummarization($event.checked);
  } */

  /* updateIsHybridSearch($event: MatCheckboxChange): void {
    this.stateService.updateIsHybridSearch($event.checked);
  } */

  /* updateIsUseSecondIndex($event: MatCheckboxChange): void {
    this.stateService.updateIsUseSecondIndex($event.checked);
  } */

  /* trackByIndex(index: number, item: any): number {
    return index;
  } */
}
