import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Subject, filter, takeUntil } from 'rxjs';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService
} from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest
} from '@azure/msal-browser';

import { environment } from '../environments/environment';
import { StateService } from './core/data-access/state.service';
import { AccessTokenService } from './core/data-access/access-token.service';
import { MenuRootRoute, MenuSubRoute } from './app.routes';
import { ThemeColorService } from './util/theme-color.service';
import { TitleService } from './util/title.service';

// eslint-disable-next-line @typescript-eslint/ban-types
//declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  stateService = inject(StateService);
  private authService = inject(MsalService);
  private accessTokenService = inject(AccessTokenService);
  private msalBroadcastService = inject(MsalBroadcastService);
  private router = inject(Router);
  private themeColorService = inject(ThemeColorService);
  private titleService = inject(TitleService);
  private translate = inject(TranslateService);
  @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration;

  isTabletLandscapeUp$ = this.stateService.isTabletLandscapeUp$;
  isSideNavOpen$ = this.stateService.isSideNavOpen$;

  isActiveAccount = false;
  activeAccountName: string | undefined = undefined;
  isIframe = false;
  isMenuMobileVisible = false;

  menuRootRoute = MenuRootRoute;
  menuSubRoute = MenuSubRoute;

  private readonly _destroying$ = new Subject<void>();
  protected readonly environment = environment;

  constructor() {
    this.translate.setDefaultLang('us');
    this.translate.use('us');
    this.translate.addLangs(['de']);
  }

  ngOnInit() {
    this.setMSALConfigs();
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  setMSALConfigs(): void {
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    // Listen for MSAL initialization event
    this.authService.instance
      .handleRedirectPromise()
      .then(result => {
        if (result !== null && result.account !== null) {
          this.authService.instance.setActiveAccount(result.account);
        }
      })
      .catch(error => {
        console.error('Error during redirect handling:', error);
      });

    // Check if the user is already authenticated
    if (this.authService.instance.getAllAccounts().length === 0) {
      this.login();
    }

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe(async () => {
        await this.authService.instance.handleRedirectPromise();

        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.checkAndSetActiveAccount();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  async setIsActiveAccount() {
    await this.authService.instance.handleRedirectPromise();

    this.isActiveAccount =
      this.authService.instance.getAllAccounts().length > 0;
    if (this.authService.instance.getAllAccounts().length === 1) {
      this.activeAccountName =
        this.authService.instance.getActiveAccount()?.name;
      if (this.activeAccountName) {
        this.accessTokenService.activeAccountName$.next(this.activeAccountName);
      }

      const activeAccountEmail =
        this.authService.instance.getActiveAccount()?.username;
      const activeAccountEmailUsername = activeAccountEmail
        ?.replace('@nortal.com', '')
        .slice(0, 20)
        .toLowerCase();

      if (activeAccountEmail) {
        this.accessTokenService.activeAccountEmail$.next(activeAccountEmail);
      }
      if (activeAccountEmailUsername) {
        this.accessTokenService.activeAccountEmailUsername$.next(
          activeAccountEmailUsername
        );
      }

      /* -- Commenting out until both graph and our BE calls work together */
      /* if (activeAccountEmail) {
        this.accessTokenService
          .getProfilePicture()
          .pipe(take(1))
          .subscribe({
            next: (blob: Blob) => {
              this.convertBlobToDataURL(blob);
            },
            error: err => {
              console.error('Error fetching profile picture:', err);
            }
          });
      } */
    }
  }

  async checkAndSetActiveAccount() {
    const accounts = this.authService.instance.getAllAccounts();
    if (accounts.length > 0) {
      this.authService.instance.setActiveAccount(accounts[0]);
      this.setIsActiveAccount();
    } else {
      this.isActiveAccount = false;
      this.accessTokenService.activeAccountName$.next('');
    }
  }

  login() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.authService.loginRedirect();
      });
  }

  logout() {
    this.authService.logoutRedirect();
  }

  onAuthEvent($event: any) {
    if ($event.operation === 'login') {
      this.login();
    } else if ($event.operation === 'logout') {
      this.logout();
    }
  }

  toggleSideNav(isOpen: boolean): void {
    this.stateService.toggleSideNav(isOpen);
  }

  toggleIsMenuMobileVisible() {
    this.isMenuMobileVisible = !this.isMenuMobileVisible;
  }

  /* navigateToSubMenu(option: MenuSubRoute | MenuSubRoute | string) {
    if (option === MenuSubRoute.CHAT) {
      this.router.navigate([
        `/${MenuRootRoute.INTELLIGENT_SEARCH}/${MenuSubRoute.CHAT}`
      ]);
    } else if (option === MenuSubRoute.GENERATE_PROPOSAL) {
      this.router.navigate([
        `/${MenuRootRoute.DOCUMENT_GENERATOR}/${MenuSubRoute.GENERATE_PROPOSAL}`
      ]);
    } else if (option === MenuSubRoute.GENERATE_DOCUMENT) {
      this.router.navigate([
        `/${MenuRootRoute.DOCUMENT_GENERATOR}/${MenuSubRoute.GENERATE_DOCUMENT}`
      ]);
    } else if (option === MenuSubRoute.GENERATE_CONTACT) {
      this.router.navigate([
        `/${MenuRootRoute.DOCUMENT_GENERATOR}/${MenuSubRoute.GENERATE_CONTACT}`
      ]);
    } else {
      this.router.navigate([
        `/${MenuRootRoute.INTELLIGENT_SEARCH}/${MenuSubRoute.QA}`
      ]);
    }
  } */

  /* private convertBlobToDataURL(blob: Blob): void {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64data = reader.result as string;
      this.accessTokenService.activeAccountPhoto$.next(base64data);
    };
    reader.readAsDataURL(blob);
  } */

  /* setUpAnalytics() {
    this.router.events.pipe(takeUntil(this._destroying$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-1H9Z08F5N3', { page_path: event.urlAfterRedirects });
      }
    });
  } */
}
