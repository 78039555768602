import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input
} from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { Answer } from '../../../model/answer.model';
import { TranslateModule } from '@ngx-translate/core';
import { QueryService } from 'src/app/features/query/data-access/query.service';

@Component({
  selector: 'app-sources',
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    MatExpansionModule,
    TranslateModule
  ],
  templateUrl: './sources.component.html',
  styleUrls: ['./sources.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SourcesComponent {
  @Input() chatAnswer?: Answer | null;
  @Input() hideAllContents?: boolean;

  private queryService = inject(QueryService);

  answer$ = this.queryService.answer$;

  hoveredIndex = 0;

  removeHTTPS(link: string) {
    return link.replace('https://', '');
  }

  preview(_event: MouseEvent | Event, index: number) {
    this.hoveredIndex = index;
  }
}
