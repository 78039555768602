import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  catchError,
  forkJoin,
  Observable,
  Subject,
  take,
  tap,
  throwError
} from 'rxjs';

import { AccessTokenService } from 'src/app/core/data-access/access-token.service';
import { environment } from 'src/environments/environment';
import {
  ExpertModePromptBody,
  PromptRequestResponse
} from '../model/expert-mode.model';

@Injectable({
  providedIn: 'root'
})
export class ExpertModeService implements OnDestroy {
  private httpClient = inject(HttpClient);
  private accessTokenService = inject(AccessTokenService);
  private translate = inject(TranslateService);
  private snackBar = inject(MatSnackBar);
  private destroy$ = new Subject<void>();

  userPromptText$ = new BehaviorSubject<string | undefined>(undefined);
  defaultPromptText$ = new BehaviorSubject<string | undefined>(undefined);

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setDefaultPrompt(isQAPrompt?: boolean) {
    const name = isQAPrompt ? 'GET_ANSWER' : 'DEFAULT_SYSTEM_PROMPT';
    this.getDefaultPrompt(name)
      .pipe(take(1))
      .subscribe(response => {
        this.defaultPromptText$.next(
          this.removeAfterSpecialChar(response.prompt)
        );

        this.setPrompt(response.prompt);
      });
  }

  getDefaultPrompt(name: string) {
    const url = `${environment.inferenceBackendUrl}/get_prompt_from_langfuse?name=DEFAULT_SYSTEM_PROMPT`;

    return this.httpClient.get<PromptRequestResponse>(url, {
      params: { name },
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  setPrompt(prompt: string) {
    return forkJoin([
      this.postPrompt(prompt, 'EXPERT_MODE'),
      this.postPrompt(prompt, 'EXPERT_MODE_GET_ANSWER')
    ])
      .pipe(
        take(1),
        tap(([resp1, resp2]) => {
          if (resp1.status_code !== 200 || resp2.status_code !== 200) {
            this.showErrorSnackbar('settings.expertModeGetDefaultError');
            throw new Error(
              `First call: ${resp1.message} (${resp1.status_code}) ` +
                `Second call: ${resp2.message} (${resp2.status_code})`
            );
          }

          this.userPromptText$.next(this.removeAfterSpecialChar(prompt));
          this.showSuccessSnackbar('settings.expertModeSetPromptSuccess');
        }),
        catchError(err => {
          this.showErrorSnackbar('settings.expertModeGetDefaultError');
          return throwError(() => err);
        })
      )
      .subscribe();
  }

  postPrompt(prompt: string, name: string): Observable<PromptRequestResponse> {
    const url = `${environment.inferenceBackendUrl}/set_prompt`;
    const body: ExpertModePromptBody = {
      name,
      prompt,
      session_id: this.accessTokenService.activeAccountEmailUsername$.value
    };

    return this.httpClient.post<PromptRequestResponse>(url, body, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  private removeAfterSpecialChar(input: string): string {
    const specialCharIndex = input.indexOf('¦');
    return specialCharIndex !== -1
      ? input.substring(0, specialCharIndex)
      : input;
  }

  private showErrorSnackbar(messageKey: string) {
    this.snackBar.open(
      this.translate.instant(messageKey),
      this.translate.instant('buttons.close'),
      {
        panelClass: ['mat-snackbar-error'],
        duration: 3000
      }
    );
  }

  private showSuccessSnackbar(messageKey: string) {
    this.snackBar.open(
      this.translate.instant(messageKey),
      this.translate.instant('buttons.close'),
      {
        panelClass: ['mat-snackbar-success'],
        duration: 3000
      }
    );
  }
}
