import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../../environments/environment';
import { Answer, RequestState } from '../../../shared/model/answer.model';
import {
  ChatHistory,
  ChatHistoryRequest,
  ChatQueryBody,
  ChatQuerySettings,
  ModelType,
  Role,
  SearchMode
} from 'src/app/shared/model/query-body.model';
import { ChatMessage } from '../model/chat-message.model';
import { AccessTokenService } from 'src/app/core/data-access/access-token.service';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {
  private accessTokenService = inject(AccessTokenService);
  private httpClient = inject(HttpClient);
  private translateService = inject(TranslateService);

  private answerSubject$ = new BehaviorSubject<Answer>(new Answer());
  answer$ = this.answerSubject$.asObservable();

  private initialBotMessage = this.translateService.instant(
    'example.welcomeMessage'
  );

  chatHistorySubject = new BehaviorSubject<ChatMessage[]>([
    {
      userMessage: {
        user: '',
        userTimestamp: null
      },
      botMessage: {
        bot: {
          answerText: this.initialBotMessage,
          sources: [],
          runtimeInMillis: 0,
          index: '',
          error_code: null,
          prompt: null,
          userQuery: ''
        },
        botTimestamp: new Date().toLocaleString()
      }
    }
  ]);
  chat$ = this.chatHistorySubject.asObservable();
  chatUpdated$ = new BehaviorSubject<ChatMessage[]>([]);
  private chatCounter = 0;

  private requestState = this.setRequestInitialState();
  requestInProgress$ = new BehaviorSubject<boolean>(false);

  constructor() {
    this.translateService.onLangChange.subscribe(() => {
      this.updateInitialBotMessage();
    });
  }

  private updateInitialBotMessage() {
    const currentHistory = this.chatHistorySubject.getValue();
    if (currentHistory.length === 1 && currentHistory[0].botMessage.bot) {
      currentHistory[0].botMessage.bot.answerText =
        this.translateService.instant('example.welcomeMessage');
      this.chatHistorySubject.next(currentHistory);
    }
  }

  private setRequestInitialState(): RequestState {
    return {
      userQuery: '',
      sources: [],
      stream: true,
      answerText: '',
      requestInProgress: true,
      sourcesAreFound: false,
      error_code: null,
      runtimeInMillis: 0,
      index: '',
      prompt: undefined
    };
  }

  sendChatMessage(
    message: string,
    chat_history: ChatHistory[],
    chatQuerySettings: ChatQuerySettings,
    filterByFiles?: string[],
    filterByTaskId?: string[]
  ): Observable<any> {
    if (
      (filterByTaskId && filterByTaskId.length === 0) ||
      filterByFiles === undefined
    ) {
      filterByTaskId = undefined;
    }

    const url = `${environment.inferenceBackendUrl}/chat`;
    const formattedHistory = this.formatHistory(chat_history);

    const body: ChatQueryBody = {
      query: message,
      index_name:
        chatQuerySettings.index_name ??
        'nortal_web_cosine,poc_sharepoint_cosine_llama',
      similarity_top_k: chatQuerySettings.similarity_top_k ?? 5,
      session_id: this.accessTokenService.activeAccountEmailUsername$.value,
      model: chatQuerySettings.model ?? ModelType.GPT_4o,
      search_mode: chatQuerySettings.search_mode ?? SearchMode.SEMANTIC,
      streaming: chatQuerySettings.streaming ?? true,
      evaluate: false,
      chat_history: [
        ...formattedHistory,
        { content: message, role: Role.USER }
      ],
      fast: chatQuerySettings.fast ?? true,
      filters:
        filterByTaskId && filterByFiles
          ? { task_id: filterByTaskId, name: filterByFiles }
          : undefined,
      temperature: chatQuerySettings.temperature,
      expert_mode: chatQuerySettings.expert_mode
    };

    return this.httpClient.post(url, body, {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'text' as 'json'
    });
  }

  private formatHistory(history: ChatHistory[]): ChatHistoryRequest[] {
    return history.flatMap(convo => {
      if (convo.user.length) {
        return [
          { content: convo.user, role: Role.USER },
          { content: convo.bot, role: Role.ASSISTANT }
        ].filter(item => item !== null) as ChatHistoryRequest[];
      } else {
        return [{ content: convo.bot, role: Role.ASSISTANT }].filter(
          item => item !== null
        ) as ChatHistoryRequest[];
      }
    });
  }

  async handleChat(
    message: string,
    chat_history: ChatHistory[],
    chatQuerySettings: ChatQuerySettings,
    filterByFiles?: string[],
    filterByTaskId?: string[]
  ): Promise<any> {
    try {
      this.requestState = this.setRequestInitialState();
      // debugger;
      const chat = this.chatHistorySubject.getValue();
      chat.push(new ChatMessage());
      this.chatHistorySubject.next([...chat]);
      this.requestState.sourcesAreFound = false;
      this.chatCounter = chat.length - 1;
      this.requestInProgress$.next(true);
      this.requestState.userQuery = message;
      const userQueryTimestamp = new Date().toLocaleString();
      this.updateChat(userQueryTimestamp, undefined);

      const response = await this.sendChatMessage(
        message,
        chat_history,
        chatQuerySettings,
        filterByFiles,
        filterByTaskId
      ).toPromise();

      if (!response || response.length === 0) {
        const errMsg = this.translateService.instant(
          'query.errorServerResponse'
        );
        throw new Error(`${errMsg} ${response.status}`);
      }

      const reader = new ReadableStream({
        start(controller) {
          controller.enqueue(response);
          controller.close();
        }
      }).getReader();

      let dataBuffer = '';
      const start = Date();
      let streamStarted = false; // flag to indicate if stream has started
      let tempBuffer = ''; // Temporary buffer to hold characters after '['
      let isCheckingForTag = false; // Flag to indicate if we are checking for '[NO_ANSWER_FOUND]'

      // eslint-disable-next-line no-constant-condition
      while (true && reader) {
        const { value, done } = await reader!.read();
        if (done) {
          const end = Date();
          const runtimeInMillis = Date.parse(end) - Date.parse(start);
          this.requestState.runtimeInMillis = runtimeInMillis;
          this.updateChat(userQueryTimestamp, new Date().toLocaleString());
          const chatMessages = this.chatHistorySubject.getValue();
          this.chatUpdated$.next(chatMessages);
          this.updateChatHistory(chatMessages);
          break;
        }

        let chunk = value;

        if (chunk.includes('data: ')) {
          chunk = chunk
            .replace(/data: {2}-/g, '    *')
            .replace(/\ndata: /g, '')
            .replace(/data: /g, '')
            .replace(/\n$/, '');
        }
        dataBuffer += chunk;
        if (
          !this.requestState.sourcesAreFound &&
          dataBuffer.includes('__DOCS_END__')
        ) {
          const [docsString, remainingText] = dataBuffer.split('__DOCS_END__');
          const sources = JSON.parse(docsString.replace(/^data: /, ''));
          this.requestState.sources = sources;
          dataBuffer = remainingText;
          this.requestState.sourcesAreFound = true;
          this.updateChat(userQueryTimestamp);
        }

        const streamStartIndex = dataBuffer.indexOf('__STREAM_START__');
        if (streamStartIndex !== -1) {
          streamStarted = true;
          this.requestState.answerText = '';
          this.updateChat(userQueryTimestamp);
          dataBuffer = dataBuffer.substring(
            streamStartIndex + '__STREAM_START__'.length
          );
        }

        const processChar = async (char: string, delay: number) => {
          return new Promise<void>((resolve, reject) => {
            try {
              setTimeout(() => {
                this.requestState.answerText += char;
                this.updateChat(userQueryTimestamp);
                resolve();
              }, delay);
            } catch (error) {
              reject(error);
            }
          });
        };

        if (streamStarted) {
          const cleanedBuffer = dataBuffer.replace(/data: /g, '');

          const delay = 0; // Initialize to 1ms for all characters
          for (const char of cleanedBuffer) {
            if (isCheckingForTag) {
              tempBuffer += char;

              if (tempBuffer === '[NO_ANSWER_FOUND]') {
                //console.log('[NO_ANSWER_FOUND] found. Removing it.');
                tempBuffer = '';
                isCheckingForTag = false;
              } else if (!'[NO_ANSWER_FOUND]'.startsWith(tempBuffer)) {
                await processChar('[' + tempBuffer, delay);
                tempBuffer = '';
                isCheckingForTag = false;
              }
            } else {
              if (char === '[') {
                /* console.log(
                  "Found '['. Starting to check for '[NO_ANSWER_FOUND]'"
                ); */
                isCheckingForTag = true;
                tempBuffer = '['; // Initialize tempBuffer with the starting '['
              } else {
                await processChar(char, delay);
              }
            }
          }
          dataBuffer = '';
        }
      }
    } catch (error: any) {
      const err = this.translateService.instant('answer.somethingWentWrong');
      if (error.message) {
        console.error(`${err}: ${error?.message}.`);
      }
      this.requestState.error_code = error.code ?? 400;
      this.requestState.answerText = err;
      this.updateChat();
    } finally {
      this.requestState.requestInProgress = false;
      this.requestInProgress$.next(false);
    }
  }

  updateChat(userTimestamp?: string, botTimestamp?: string): void {
    const chatHistory = this.chatHistorySubject.getValue();

    const answer: Answer = {
      answerText: this.requestState.answerText,
      sources: this.requestState.sources,
      runtimeInMillis: this.requestState.runtimeInMillis,
      index: this.requestState.index,
      error_code: this.requestState.error_code,
      prompt: this.requestState.prompt,
      userQuery: this.requestState.userQuery
    };

    const chatMessage: ChatMessage = {
      userMessage: {
        user: this.requestState.userQuery,
        userTimestamp:
          this.requestState.userQuery && userTimestamp ? userTimestamp : null
      },
      botMessage: {
        bot: answer,
        botTimestamp:
          this.requestState.answerText && botTimestamp ? botTimestamp : null
      }
    };

    chatHistory[this.chatCounter] = chatMessage;
    this.chatHistorySubject.next([...chatHistory]);
  }

  updateChatHistory(val: ChatMessage[]): void {
    this.chatHistorySubject.next(val);
  }

  deleteHistory() {
    const sessionId = this.accessTokenService.activeAccountEmailUsername$.value;
    const url = `${environment.inferenceBackendUrl}/delete_history?session_id=${sessionId}`;
    return this.httpClient.delete<string>(url);
  }
}
