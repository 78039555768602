<label for="slider">
  <p>{{ sliderLabel | translate }}:</p>
  <div>
    <p>{{ slider.value }}</p>
    <mat-icon
      matTooltip="{{ sliderTooltip | translate }}"
      class="tooltip-icon"
      inline>
      info
    </mat-icon>
  </div>
</label>
<mat-slider [min]="min" [max]="max" [step]="step" showTickMarks discrete>
  <input
    matSliderThumb
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)"
    #slider
    (valueChange)="
      isTemperature
        ? updateExpertModeTemperature($event)
        : updateTopKValue($event)
    " />
</mat-slider>
