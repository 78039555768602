import { Environment } from './environment.model';

export const environment: Environment = {
  name: 'development',
  appTitle: 'Tark - Development',
  production: false,
  themeColor: '#ffa500',
  inferenceBackendUrl: 'https://backend-dev.swedencentral.cloudapp.azure.com',
  msalConfig: {
    auth: {
      clientId: '39d0b89e-acdf-4a1d-8dc9-b73aae8cd409',
      authority:
        'https://login.microsoftonline.com/7c359f6b-9f2f-4042-bf2d-b08ce16c5cc1/v2.0/',
      redirectUri: 'https://nortaltark-dev.swedencentral.cloudapp.azure.com'
    }
  },
  apiConfig: {
    scopes: [
      'openid',
      'email',
      'offline_access',
      //'User.Read',
      'api://39d0b89e-acdf-4a1d-8dc9-b73aae8cd409/user_impersonation'
    ],
    uri: 'https://graph.microsoft.com/v1.0/me'
  },
  ingestionBackendUrl:
    'https://ingestion-backend-dev.swedencentral.cloudapp.azure.com',
  azureLogicAppUrl:
    'https://prod-13.westeurope.logic.azure.com:443/workflows/09acf2cd29fe46a3a401ade15fadd461/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ShbCowgWTK0LJmh5kG96YF8-n9iGgIxWRpFbX4lSgSg'
};
