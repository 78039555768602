import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  inject
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StateService } from 'src/app/core/data-access/state.service';

@Component({
  selector: 'app-slider',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    FormsModule,
    MatSliderModule,
    TranslateModule,
    MatIconModule,
    MatTooltipModule
  ],
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderComponent implements OnChanges {
  @Input() sliderLabel = 'settings.sliderLabel';
  @Input() sliderTooltip = 'settings.sliderTooltip';
  @Input() min = 3;
  @Input() max = 15;
  @Input() step = 1;
  @Input() isTemperature = false;
  @Input() defaultValue: number | null | undefined = 0.1;

  private stateService = inject(StateService);

  value = 6;

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['defaultValue'] &&
      changes['defaultValue'].currentValue !== undefined
    ) {
      this.value = changes['defaultValue'].currentValue;
    }
  }

  onValueChange(newValue: number) {
    this.value = newValue;
    if (this.isTemperature) {
      this.updateExpertModeTemperature(newValue);
    } else {
      this.updateTopKValue(newValue);
    }
  }

  updateTopKValue($event: number) {
    this.stateService.updateTopKValue($event);
  }

  updateExpertModeTemperature($event: number) {
    this.stateService.updateExpertModeTemperature($event);
  }
}
