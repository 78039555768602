import { BrowserModule } from '@angular/platform-browser';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
  provideAnimations
} from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule, importProvidersFrom } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MsalGuardConfiguration,
  MsalRedirectComponent
} from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { MarkdownModule, MARKED_OPTIONS } from 'ngx-markdown';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RouterModule, provideRouter } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EnvironmentNotificationComponent } from './shared/ui/environment-notification/environment-notification.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { HeaderComponent } from './core/ui/header/header.component';
import { ChatbotFeatureComponent } from './features/chatbot/feature/chatbot-feature.component';
import { QueryHistoryComponent } from './features/query-history/feature/query-history.component';
import { ReleaseNotesComponent } from './features/release-notes/feature/release-notes/release-notes.component';
import { SettingsComponent } from './features/settings/feature/settings.component';
import { ROUTES } from './app.routes';
import { ExpertModeComponent } from './features/expert-mode/feature/expert-mode/expert-mode.component';
import { AuthInterceptor } from './core/data-access/auth.interceptor';
import { SubMenuComponent } from './core/ui/sub-menu/sub-menu.component';

export function loggerCallback(_logLevel: LogLevel, message: string) {
  console.log(message);
}

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function initializeApp(msalService: MsalService): () => Promise<void> {
  return async () => {
    await msalService.instance.initialize();
    await msalService.instance.handleRedirectPromise();
    const activeAccount = msalService.instance.getActiveAccount();
    if (!activeAccount) {
      msalService.loginRedirect();
    }
  };
}

const msalConfig = {
  auth: {
    clientId: environment.msalConfig.auth.clientId,
    authority: environment.msalConfig.auth.authority,
    redirectUri: environment.msalConfig.auth.redirectUri
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      //loggerCallback, // Enable this for console logging MSAL info
      logLevel: LogLevel.Info,
      piiLoggingEnabled: false
    }
  }
};

export function MSALInstanceFactory(): IPublicClientApplication {
  const msalInstance = new PublicClientApplication(msalConfig);
  //msalInstance.initialize();
  return msalInstance;
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(
    environment.apiConfig.uri,
    environment.apiConfig.scopes
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.apiConfig.scopes]
    }
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent, MsalRedirectComponent],
  imports: [
    AppRoutingModule,
    AsyncPipe,
    BrowserAnimationsModule,
    BrowserModule,
    ChatbotFeatureComponent,
    EnvironmentNotificationComponent,
    ExpertModeComponent,
    HeaderComponent,
    SubMenuComponent,
    MatButtonModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MsalModule,
    NgIf,
    NoopAnimationsModule, // Animations cause delay which interfere with E2E tests
    QueryHistoryComponent,
    ReleaseNotesComponent,
    RouterModule,
    SettingsComponent,
    TranslateModule,
    MatTabsModule,
    MsalModule.forRoot(
      MSALInstanceFactory(),
      MSALGuardConfigFactory(),
      MSALInterceptorConfigFactory()
    )
  ],
  providers: [
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'us',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
      MarkdownModule.forRoot({
        markedOptions: {
          provide: MARKED_OPTIONS,
          useValue: {
            gfm: true,
            breaks: true
          }
        }
      }),
      MatSnackBarModule,
      BrowserModule,
      MatSidenavModule,
      MatButtonModule,
      MatIconModule
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [MsalService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    provideAnimations(),
    provideRouter(ROUTES),
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
