import { ClientName } from 'src/app/shared/model/query-body.model';
import {
  AnswerType,
  Configuration,
  SearchIndex
} from '../configurations.model';

export const OIKEUSREKISTERIKESKUS: Configuration = {
  title: 'Intelligent Search',
  shortName: ClientName.OIKEUSREKISTERIKESKUS,
  logoFileName: 'oikeusrekisterikeskus.png',
  searchSettings: {
    index_name: SearchIndex.OIKEUSREKISTERIKESKUS,
    answerType: AnswerType.LFGA,
    retriever_number: 5,
    temperature: 0.1,
    document_chat_index: SearchIndex.DOCUMENT_CHAT_INDEX
  },
  examples: [
    {
      language: 'english',
      flagClass: 'us',
      questions: [
        'What is Oikeusrekisterikeskus?',
        'What fields does Oikeusrekisterikeskus operate in?',
        'How can I apply for payment time?',
        'Could you provide me with a link to a blank criminal record document?',
        'How has the history of Oikeusrekisterikeskus evolved over time?'
      ]
    },
    {
      language: 'finnish',
      flagClass: 'fi',
      questions: [
        'Mikä on Oikeusrekisterikeskus?',
        'Millä aloilla Oikeusrekisterikeskus toimii?',
        'Näin haet maksuaikaa',
        'Voisitko antaa minulle linkin tyhjään rikosrekisteri asiakirjaan?',
        'Miten Oikeusrekisterikeskuksen historia on kehittynyt ajan myötä?'
      ]
    },
    {
      language: 'swedish',
      flagClass: 'se',
      questions: [
        'Vad är Oikeusrekirikeskus?',
        'Vilka områden verkar Oikeusrekirikeskus inom?',
        'Så ansöker du om betalningstid',
        'Kan du ge mig en länk till ett tomt kriminalregister?',
        'Hur har historien om Oikeusrekirikeskus utvecklats över tid?'
      ]
    }
  ]
};
