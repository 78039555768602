<div class="outer-container">
  <ng-container *ngIf="chat$ | async as chatHistory">
    <mat-divider></mat-divider>

    <div [ngClass]="classes" class="inner-container">
      <div class="chat-container scrollbar" #chatContainer>
        <div *ngIf="isLoading$ | async" class="spinner">
          <mat-spinner [diameter]="60"></mat-spinner>
        </div>

        <app-chat-message
          *ngFor="let chatMessage of chatHistory"
          [chatMessage]="chatMessage"
          (scrollPositionY)="onScrollPositionChange($event)"
          [isLoading]="(chatbotService.requestInProgress$ | async)!">
        </app-chat-message>
        <img
          *ngIf="chatbotService.requestInProgress$ | async"
          class="loading-dots"
          src="assets/images/dots.gif"
          alt="waiting ..." />
      </div>

      <app-chatbot-example-chips
        *ngIf="
          chatHistory.length === 1 && !this.selectedFileNames?.length
        "></app-chatbot-example-chips>

      <div class="query-field-container">
        <button
          *ngIf="(chatHistory ?? []).length > 1"
          mat-flat-button
          class="clear-button"
          color="warn"
          [disabled]="
            (isLoading$ | async)! ||
            (chatbotService.requestInProgress$ | async)!
          "
          (click)="clearHistory()">
          {{ 'chat.clear' | translate }}
        </button>

        <app-query-field
          [isShowFiltersButton]="false"
          [isShouldReset]="true"
          [isShowDataIncludedButton]="false"
          [isLoading]="
            (isLoading$ | async)! ||
            (chatbotService.requestInProgress$ | async)!
          "
          [example]="(exampleQuestion$ | async) ?? ''"
          [featureClass]="'chatbot-feature'"
          (sendQuery)="onSendMessage($event)">
        </app-query-field>
      </div>
    </div>
  </ng-container>
</div>
