<div class="message-container">
  <div class="user-message" *ngIf="chatMessage?.userMessage?.user">
    <div class="w-100">
      <div
        class="message-header"
        *ngIf="
          (stateService.selectedConfiguration$ | async)?.shortName as shortName
        ">
        <span class="user-icon"><mat-icon>person</mat-icon></span>
        <p>{{ accessTokenService.activeAccountName$ | async }}</p>
        <span class="message-time">{{
          chatMessage.userMessage.userTimestamp
        }}</span>
      </div>
      <div class="message-content">
        <p class="message-text">{{ chatMessage.userMessage.user }}</p>
      </div>
    </div>
  </div>
  <div class="bot-message" *ngIf="chatMessage?.botMessage?.bot">
    <div class="w-100">
      <div class="message-header" *ngIf="messageSenderName$ | async as botName">
        <span class="sender-initial-logo">{{ botName[0] }}</span>
        <p>{{ botName }}</p>
        <span class="message-time">
          {{ chatMessage.botMessage.botTimestamp }}
        </span>
      </div>
      <div class="message-content">
        <markdown
          *ngIf="chatMessage?.botMessage?.bot?.answerText?.length"
          class="message-text"
          [data]="chatMessage.botMessage.bot?.answerText">
        </markdown>

        <button
          *ngIf="chatMessage.botMessage.bot?.sources?.length && !isLoading"
          class="detail-button"
          (click)="toggleSources($event)">
          {{
            isShowSources
              ? ('answer.hideSources' | translate)
              : ('answer.showSources' | translate)
          }}
        </button>
        <app-sources
          *ngIf="chatMessage && isShowSources"
          [chatAnswer]="chatMessage.botMessage.bot"
          [hideAllContents]="false">
        </app-sources>
      </div>
    </div>
  </div>
</div>
