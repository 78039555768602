import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  constructor() {
    this.setTitle(environment.appTitle);
  }

  setTitle(title: string): void {
    document.title = title;
  }
}
