import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccessTokenService {
  private httpClient = inject(HttpClient);

  activeAccountName$ = new BehaviorSubject<string>('');
  activeAccountEmail$ = new BehaviorSubject<string>('');
  activeAccountEmailUsername$ = new BehaviorSubject<string>('');
  activeAccountPhoto$ = new BehaviorSubject<unknown>(undefined);
  canAccessPostHog$ = new BehaviorSubject<boolean>(false);

  getProfilePicture() {
    return this.httpClient.get(
      'https://graph.microsoft.com/v1.0/me/photo/$value',
      { responseType: 'blob' }
    );
  }
}
